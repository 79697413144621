import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Header, Content, Wrapper } from '../components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loggedIn } from '../actions/auth';
import config from '../config/config';

class Home extends Component {
  componentDidMount() {
    document.title = `AJN PDFS`;
    console.log(process.env);
  }
  componentDidMount() {
    // as this is the only accessible page
    // when auth is expired
    // but client thinks still logged in
    // we need to run through the logout
    // actions if necessary
    // (essentially when client was logged in but now
    // the refresh token has expired)
    this.props
      .loggedIn()
      .then((res) => {
        this.setState({ loggedIn: true });
        console.log('loggedin ✅');
        if (res === 'refreshed') {
          // we got a new cookie; refresh the page in case of side effects.
          window.location.reload();
        }
      })
      .catch((err) => {
        this.setState({ loggedIn: false });
        console.log(err);
      });
  }
  render() {
    const { isLogged } = this.props;
    return (
      <div>
        <Header
          title="AJN pdfs"
          description="PDF distribution & management platform."
          style={{
            backgroundImage: `url(${require('../img/undraw_filing_system_b5d2.svg')})`,
          }}
        />
        <Content>
          {!isLogged && (
            <Wrapper>
              <Button component={Link} to="/login">
                Login
              </Button>
            </Wrapper>
          )}
          <Wrapper style={{ position: 'absolute', bottom: 0, right: 24 }}>
            <pre>{config.version}</pre>
            <Typography variant="caption">
              Made with ☕ by{' '}
              <a
                style={{ textDecoration: 'none', color: 'inherit' }}
                href="https://github.com/renrizzolo"
              >
                Ren Rizzolo
              </a>
            </Typography>
          </Wrapper>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.root.auth.isLogged,
});
const mapDispatchToProps = (dispatch) => ({
  loggedIn: () => dispatch(loggedIn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
